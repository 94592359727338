<template>
    <div id="profile-page">
        <toolbar-back-button-component :title="$t('profile_page.manage_account')" />

        <div class="pa-4">
            <p class="font-weight-bold">{{ $t('profile_page.personal_information') }}</p>

            <div class="d-flex justify-center mb-4 edit-profile-icon-hover">
                <span @click="$refs.DialogEditBadgeUserComponent.openDialogEditBadgeUser($store.getters['user_connected/user_connected'])">
                    <profile-picture-component :user="$store.getters['user_connected/user_connected']" :width="120" />
                </span>
            </div>

            <div id="last-name" class="mb-2">
                <div class="white-text-field">
                    <v-text-field
                        v-model="user.last_name"
                        :label="$t('profile_page.last_name')"
                        color="secondary"
                        data-cy="last-name-input"
                        filled
                        hide-details
                        readonly
                        @click="openDialogEdition()"
                    >
                        <template v-slot:append>
                            <v-icon @click="openDialogEdition()"> mdi-chevron-right</v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>

            <div id="first-name" class="mb-2">
                <div class="white-text-field">
                    <v-text-field
                        v-model="user.first_name"
                        :label="$t('profile_page.first_name')"
                        color="secondary"
                        data-cy="first-name-input"
                        filled
                        hide-details
                        readonly
                        @click="openDialogEdition()"
                    >
                        <template v-slot:append>
                            <v-icon @click="openDialogEdition()"> mdi-chevron-right</v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>

            <div id="phone" class="mb-2">
                <div class="white-text-field">
                    <v-text-field
                        v-model="user.phone"
                        :label="$t('profile_page.phone')"
                        color="secondary"
                        filled
                        hide-details
                        readonly
                        @click="openDialogEdition()"
                    >
                        <template v-slot:append>
                            <v-icon @click="openDialogEdition()"> mdi-chevron-right</v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>

            <v-dialog v-model="dialog_edition" max-width="500px">
                <v-card data-cy="dialog-edition">
                    <v-card-title>
                        <p class="font-weight-bold font-18 text-uppercase">
                            {{ $t('profile_page.modify_information') }}
                        </p>
                    </v-card-title>

                    <v-form ref="form_profile">
                        <v-card-text class="pb-0">
                            <v-text-field
                                v-model="edit_user.last_name"
                                :label="$t('profile_page.last_name')"
                                :rules="profile_rules"
                                color="secondary"
                                data-cy="last-name-input"
                                filled
                            />

                            <v-text-field
                                v-model="edit_user.first_name"
                                :label="$t('profile_page.first_name')"
                                :rules="profile_rules"
                                color="secondary"
                                data-cy="last-name-input"
                                filled
                            />

                            <vue-phone-number-input
                                v-model="edit_user.phone"
                                :required="true"
                                :translations="{
                                    countrySelectorLabel: $t('form_coordinate_stickers_component.code_country'),
                                    countrySelectorError: $t('form_coordinate_stickers_component.choose_country'),
                                    phoneNumberLabel: $t('form_coordinate_stickers_component.phone_number'),
                                    example: $t('form_coordinate_stickers_component.example'),
                                }"
                                class="mb-8"
                                default-country-code="FR"
                                size="lg"
                                v-on:update="setValidPhone"
                            />
                        </v-card-text>

                        <v-card-actions class="justify-end pb-4">
                            <v-btn data-cy="cancel-btn" text @click="closeDialogEditionProfile()">
                                <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                            </v-btn>

                            <v-btn
                                :loading="loading_edit"
                                class="btn-secondary-loader"
                                color="secondary"
                                data-cy="submit-btn"
                                text
                                @click="updateProfileUser()"
                            >
                                <span class="secondary--text font-weight-bold">{{ $t('global.validate') }}</span>
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>

            <div id="password" class="mb-2">
                <div class="white-text-field">
                    <v-text-field
                        :label="$t('login_account_component.password')"
                        color="secondary"
                        data-cy="password-input"
                        filled
                        hide-details
                        readonly
                        type="password"
                        value="password1234"
                        @click="dialog_edition_password = true"
                    >
                        <template v-slot:append>
                            <v-icon @click="dialog_edition_password = true"> mdi-chevron-right</v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>

            <v-dialog v-model="dialog_edition_password" max-width="500px">
                <v-card data-cy="dialog-edition-password">
                    <v-card-title>
                        <p class="font-weight-bold font-18 text-uppercase">{{ $t('profile_page.modify_password') }}</p>
                    </v-card-title>

                    <v-form ref="form_password">
                        <v-card-text class="pb-0 pt-0">
                            <v-text-field
                                v-model="old_password"
                                :append-icon="show_old_password ? 'mdi-eye' : 'mdi-eye-off'"
                                :label="$t('profile_page.actual_password')"
                                :rules="[(l) => !!l || $t('profile_page.actual_password_required')]"
                                :type="show_old_password ? 'text' : 'password'"
                                color="secondary"
                                filled
                                @click:append="show_old_password = !show_old_password"
                            />

                            <v-text-field
                                v-model="new_password"
                                :append-icon="show_new_password ? 'mdi-eye' : 'mdi-eye-off'"
                                :label="$t('profile_page.new_password')"
                                :rules="new_password_rules"
                                :type="show_new_password ? 'text' : 'password'"
                                color="secondary"
                                filled
                                @click:append="show_new_password = !show_new_password"
                            />

                            <v-text-field
                                v-model="new_password_confirm"
                                :append-icon="show_new_password_confirm ? 'mdi-eye' : 'mdi-eye-off'"
                                :label="$t('profile_page.confirm_new_password')"
                                :rules="new_password_confirm_rules"
                                :type="show_new_password_confirm ? 'text' : 'password'"
                                color="secondary"
                                filled
                                @click:append="show_new_password_confirm = !show_new_password_confirm"
                                @keypress.enter="updatePasswordUser()"
                            />
                        </v-card-text>

                        <v-card-actions class="justify-end pb-4">
                            <v-btn color="accent" data-cy="password-cancel-btn" text @click="closeDialogEditionPassword()">
                                <span class="font-weight-bold font-color-light">{{ $t('global.cancel') }}</span>
                            </v-btn>
                            <v-btn
                                :loading="loading_edit"
                                class="font-weight-bold btn-secondary-loader"
                                color="secondary"
                                text
                                @click="updatePasswordUser()"
                            >
                                <span class="secondary--text font-weight-bold">{{ $t('global.validate') }}</span>
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>

            <div id="language" class="mb-2 mt-4">
                <div class="pointer" @click="language_sheet = true">
                    <v-text-field
                        v-model="selected_language.name"
                        :label="$t('profile_page.language')"
                        color="secondary"
                        hide-details
                        readonly
                    >
                        <template v-slot:append>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:prepend-inner>
                            <div class="mb-2 mr-2">
                                <country-flag :country="parseLanguage(selected_language.key, true)" />
                            </div>
                        </template>
                    </v-text-field>
                </div>

                <v-bottom-sheet v-model="language_sheet">
                    <v-sheet v-touch:swipe.bottom="swipeBottomHandler">
                        <v-progress-linear v-if="loading_edit" color="primary" height="2" indeterminate />

                        <div class="pa-4">
                            <p class="font-weight-bold font-18 text-uppercase">{{ $t('profile_page.language_choice') }}</p>

                            <v-radio-group v-model="selected_language" :readonly="loading_edit" mandatory @change="editLanguage">
                                <v-radio
                                    v-for="language in languages"
                                    :key="language.key"
                                    :label="language.name"
                                    :value="language"
                                    class="mt-0"
                                    color="secondary"
                                >
                                    <template v-slot:label>
                                        <div class="d-flex justify-center align-center gap-2">
                                            <div class="pb-1">
                                                <country-flag :country="parseLanguage(language.key, true)" />
                                            </div>
                                            {{ language.name }}
                                        </div>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </div>
                    </v-sheet>
                </v-bottom-sheet>
            </div>

            <v-divider class="my-4" color="white" />

            <div>
                <p class="font-weight-bold">{{ $t('profile_page.contact_preferences') }}</p>

                <v-row class="ma-0" justify="space-around">
                    <template v-for="alertPreference in alert_preferences">
                        <div
                            :key="alertPreference.key"
                            class="pointer"
                            @click="prepareUpdateUserAlertContactPreferences(alertPreference.key)"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        :color="
                                            user_alert_preferences[alertPreference.key]
                                                ? $store.getters['display_option/display_option'].primary
                                                : $store.getters['display_option/display_option'].error
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        {{ alertPreference.icon }}
                                    </v-icon>
                                </template>

                                <template v-slot:default>
                                    {{
                                        user_alert_preferences[alertPreference.key]
                                            ? $t(`profile_page.can_receive_${alertPreference.key}`)
                                            : $t(`profile_page.cannot_receive_${alertPreference.key}`)
                                    }}
                                </template>
                            </v-tooltip>
                        </div>
                    </template>
                </v-row>
            </div>

            <v-progress-linear
                :color="updating_user_alert_contact ? 'primary' : 'grey'"
                :indeterminate="updating_user_alert_contact"
                class="my-4"
                height="1"
            />
            <v-btn block class="font-weight-bold mt-2" color="red" outlined small @click="dialog_remove_access = true">
                <span class="error--text">{{ $t('profile_page.delete_my_access_space') }}</span>
            </v-btn>
        </div>

        <v-dialog v-model="dialog_remove_access" max-width="500px">
            <v-card>
                <v-container>
                    <v-card-text class="text-center pb-1">
                        <p class="text-uppercase font-18 font-weight-bold red--text mb-2">
                            {{ $t('profile_page.delete_my_access_space') }}
                        </p>
                        <v-icon class="mx-auto" color="error" x-large>mdi-account-remove</v-icon>
                    </v-card-text>

                    <v-card-text class="font-weight-medium pb-0 pt-1">
                        <p class="mb-1">
                            {{
                                $t('profile_page.after_deletion_no_ore_access', [
                                    $store.getters['user_connected/user_connected'].professional_name,
                                ])
                            }}
                        </p>
                        <p>{{ $t('profile_page.access_remove_space') }}</p>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialog_remove_access = false">
                            <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>

                        <v-btn :loading="loading_edit" class="font-weight-bold btn-error-loader" color="error" text @click="removeAccess()">
                            <span class="error--text">{{ $t('global.delete') }}</span>
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>

        <dialog-edit-badge-user-component
            ref="DialogEditBadgeUserComponent"
            :user="user"
            v-on:update:user-badge="$store.dispatch('user_connected/getAuthenticateUser')"
        />
    </div>
</template>

<script>
import UserRepository from '@/repositories/UserRepository'
import ToolbarBackButtonComponent from '@/components/Global/ToolbarBackButtonComponent'
import DialogEditBadgeUserComponent from '@/components/Profile/DialogEditBadgeUserComponent.vue'
import ProfilePictureComponent from '@/components/Profile/ProfilePictureComponent.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { setI18nLocalLanguage } from '@/plugins/i18n'
import CountryFlag from 'vue-country-flag'

export default {
    name: 'ProfilePage',
    components: {
        ProfilePictureComponent,
        DialogEditBadgeUserComponent,
        ToolbarBackButtonComponent,
        VuePhoneNumberInput,
        CountryFlag,
    },
    data() {
        return {
            user: this.$store.getters['user_connected/user_connected'],
            edit_user: this.$store.getters['user_connected/user_connected'],
            user_alert_preferences: { alert_badge: true, alert_mail: false, alert_push: false, alert_sms: false },
            updating_user_alert_contact: false,
            alert_preferences: [
                /* ALERT_BADGE
                {
                    key: 'alert_badge',
                    icon: 'mdi-eye-circle-outline',
                },*/
                {
                    key: 'alert_push',
                    icon: 'mdi-cellphone-message',
                },
                {
                    key: 'alert_sms',
                    icon: 'mdi-phone',
                },
                {
                    key: 'alert_mail',
                    icon: 'mdi-at',
                },
            ],
            dialog_edition: false,
            loading_edit: false,

            profile_rules: [(l) => !!l || this.$t('profile_page.field_required')],

            is_phone_number_valid: false,
            formatted_number: '',

            dialog_edition_password: false,
            old_password: '',
            show_old_password: false,
            password_structure: /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{12,}$/,
            new_password: '',
            show_new_password: false,
            new_password_rules: [
                (v) => !!v || this.$t('profile_page.new_password_required'),
                (v) => this.password_structure.test(v) || this.$t('profile_page.new_password_rule_creation'),
            ],
            new_password_confirm: '',
            show_new_password_confirm: false,
            new_password_confirm_rules: [
                (v) => !!v || this.$t('profile_page.confirm_new_password_required'),
                (v) => this.new_password === v || this.$t('profile_page.password_do_not_match'),
            ],

            language_sheet: false,
            selected_language: {
                key: 'default',
                name: this.$t('profile_page.by_default'),
            },
            languages: [
                {
                    key: 'default',
                    name: this.$t('profile_page.by_default'),
                },
                {
                    key: 'fr',
                    name: 'Français',
                },
                {
                    key: 'en',
                    name: 'English',
                },
                {
                    key: 'de',
                    name: 'Deutsch',
                },
                {
                    key: 'es',
                    name: 'Español',
                },
                {
                    key: 'it',
                    name: 'Italiano',
                },
                {
                    key: 'nl',
                    name: 'Nederlands',
                },
                {
                    key: 'pt',
                    name: 'Português',
                },
            ],
            dialog_remove_access: false,
        }
    },
    created() {
        //First /me is called after component created, so we need to do it again to have updated user information
        this.$auth.load().then(async () => {
            this.$auth.fetch({}).then((result) => {
                this.user = result.data.data
                this.user_alert_preferences = this.user
            })
        })

        this.selected_language =
            window.localStorage.getItem('use_navigator_language') === 'true'
                ? this.languages.find((language) => language.key === 'default')
                : this.languages.find((language) => language.key === (this.user.language ?? 'default'))
    },
    methods: {
        openDialogEdition() {
            this.edit_user = { ...this.user }
            this.dialog_edition = true
        },

        prepareUpdateUserAlertContactPreferences(alert_preference_key) {
            this.user_alert_preferences[alert_preference_key] = !this.user_alert_preferences[alert_preference_key]
            this.updateUserAlertContactPreferences()
        },

        updateUserAlertContactPreferences() {
            this.updating_user_alert_contact = true
            UserRepository.updateUserProfile(this.user_alert_preferences)
                .then(() => {
                    this.showSnackbar('success', this.$t('profile_page.user_alert_preferences_updated'))
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.updating_user_alert_contact = false
                })
        },

        setValidPhone(value) {
            this.is_phone_number_valid = value.isValid
            this.formatted_number = value.formattedNumber
        },

        updateProfileUser() {
            if (this.$refs.form_profile.validate() && this.is_phone_number_valid) {
                this.loading_edit = true
                this.edit_user.phone = this.formatted_number
                UserRepository.updateUserProfile(this.edit_user)
                    .then((success) => {
                        this.$store.dispatch('user_connected/setSomePropsAuthenticateUser', success.data.data)
                        this.user = { ...this.edit_user }
                        this.showSnackbar('success', this.$t('profile_page.success_profile_updated'))
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.closeDialogEditionProfile()
                        this.loading_edit = false
                    })
            }
        },

        closeDialogEditionProfile() {
            this.dialog_edition = false
            this.$refs.form_profile.reset()
        },

        updatePasswordUser() {
            if (this.$refs.form_password.validate()) {
                this.loading_edit = true
                UserRepository.updateUserPassword(this.old_password, this.new_password, this.new_password_confirm)
                    .then(() => {
                        this.showSnackbar('success', this.$t('profile_page.success_password_updated'))
                        this.closeDialogEditionPassword()
                    })
                    .catch((error) => {
                        this.manageError(error)
                        this.loading_edit = false
                    })
            }
        },

        closeDialogEditionPassword() {
            this.password = ''
            this.new_password = ''
            this.new_password_confirm = ''
            this.dialog_edition_password = false
            this.$refs.form_password.reset()
        },

        async editLanguage() {
            if (this.selected_language.key !== 'default') {
                window.localStorage.setItem('use_navigator_language', JSON.stringify(false))
                setI18nLocalLanguage(this.selected_language.key)
                this.user.language = this.selected_language.key

                this.loading_edit = true
                await UserRepository.updateUserProfile(this.user)
                    .then((success) => {
                        this.$store.dispatch('user_connected/setSomePropsAuthenticateUser', success.data.data)
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.loading_edit = false
                    })
            } else {
                window.localStorage.setItem('use_navigator_language', JSON.stringify(true))
                setI18nLocalLanguage(navigator.language)
            }

            this.language_sheet = false
            window.location.reload()
        },

        swipeBottomHandler() {
            this.language_sheet = false
        },

        removeAccess() {
            this.loading_edit = true
            UserRepository.deleteUserAccess(this.$store.getters['user_connected/user_connected'].id_client)
                .then(() => {
                    this.showSnackbar('success', this.$t('profile_page.access_remove_successfully'))
                    this.logout()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_edit = false
                    this.dialog_remove_access = false
                })
        },
    },
}
</script>

<style>
.edit-profile-icon-hover .profile-picture-circle:hover::before {
    opacity: 0.4;
}

.edit-profile-icon-hover .profile-picture-circle {
    cursor: pointer;
}

.edit-profile-icon-hover .profile-picture-circle:hover::after {
    opacity: 1;
}

.edit-profile-icon-hover .profile-picture-circle::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #464646;
    opacity: 0;
    border-radius: 100%;
    transition: all 0.2s ease;
}

.edit-profile-icon-hover .profile-picture-circle::after {
    content: '\F03EB';
    font: normal normal normal 52px/1 'Material Design Icons';
    position: absolute;
    color: white;
    opacity: 0;
    transition: all 0.2s ease;
}

.input-tel__input {
    border: none !important;
    border-bottom: 1px solid #7f828a !important;
    border-radius: 0 !important;
    height: 56px !important;
    background: #f5f7fb !important;
}

.input-tel__input:focus {
    border: none !important;
}

.country-selector__input {
    border: none !important;
    border-bottom: 1px solid #7f828a !important;
    border-right: 1px solid black !important;
    border-radius: 0 !important;
    height: 56px !important;
    background: #f5f7fb !important;
}
</style>
